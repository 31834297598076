/*
https://dollarsprout.go2cloud.org/aff_c
  ?offer_id=12
  &aff_id=1
  &aff_sub=best-side-hustle-ideas

https://r.financebuzz.com/aff_c
  ?source=%2Fways-to-make-extra-money
  &offer_id=15020
  &aff_id=1006
  &aff_sub=google.com
  &aff_sub2=US_SideHustle_ROAS_6523447166_147501793673
  &aff_sub3=how%20to%20make%20side%20money
  &aff_sub4=organic%20search
  &aff_sub5=pageviewid=k4ZMEhELbbQT0wdUJPJi,contentblockid=1800,contentblockversionid=8290,offerid=15020,adcampaignid=,contentblockposition=1,mlsortid=,sorteditemid=
  &aff_click_id=Cj0KCQjw8qmhBhClARIsANAtbofHF0f37Ep3yo5ACIKSj-95bjUuJi06cuawPMzvfh4tWrqfwUiZisQaAmnUEALw_wcB
  &aff_unique1=fb.1.1677174385183.1193807288
  &aff_unique2=e83E7jeRgxlztJT3S5f
  &aff_unique3=6523447166
  &aff_unique4=147501793673
  &aff_unique5=
  &rendered_slug=/ways-to-make-extra-money
  &contentblockid=1800
  &contentblockversionid=8290
  &ml_sort_id=
  &sorted_item_id=
  &widget_type=
  &cms_offer_id=498

https://forward.atm.com/click
  ?uid=8d6f4254-332c-4db2-93be-aca07adbf70a
  &src=MARKETING_CAMPAIGN
  &mcid=
  &poid=4afca964-c1bd-4052-8167-5520385dcf50
  &plid=a5d60b5f-9b28-41fd-96a4-b207ba4198f5

URLS FOR TRACKING:
  ?utm_source=Website&utm_medium=website&utm_campaign=Download_Button
  ?utm_source=Blog&utm_medium=website&utm_campaign=[blog post name / cta name]
  ?utm_source=Home_Page&utm_medium=website&utm_campaign=Header_Download
  ?utm_source=About_Page&utm_medium=website&utm_campaign=Footer_Download
*/

import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import {
  Accordion,
  Container,
  FormSelect,
  Pagination,
  FloatingLabel,
} from "react-bootstrap"
import { Layout2022Q3 } from "@layout"
import SEO from "@components/seo"
import { HeroColony2022Q3 } from "@components/hero"
import { MainBlockJoinColony } from "@components/main-blocks"
import "@styles/pages/_content.scss"

export default function PostsTemplate({
  location,
  data: { allMarkdownRemark },
}) {
  const postsPerPage = 10
  const allMarkdownRemarkEdges = allMarkdownRemark.edges

  const getUrlParams = (search, paramName) => {
    const urlParams = new URLSearchParams(search)
    return urlParams.get(paramName) || ""
  }

  const initialTagFilter = getUrlParams(location.search, "tag")
  const [tagFilter, setTagFilter] = useState(initialTagFilter)

  const filteredEdges = tagFilter
    ? allMarkdownRemarkEdges.filter(({ node: { frontmatter } }) =>
        frontmatter.tags.includes(tagFilter)
      )
    : allMarkdownRemarkEdges
  const totalPages = Math.ceil(filteredEdges.length / postsPerPage)

  const getPageParam = (searchString) => {
    let pageParam = parseInt(getUrlParams(searchString, "page"))
    if (pageParam > totalPages) {
      pageParam = totalPages
    }
    return pageParam || 1
  }

  const [currentPage, setCurrentPage] = useState(() =>
    getPageParam(location.search)
  )

  useEffect(() => {
    const newTagFilter = getUrlParams(location.search, "tag")
    setTagFilter(newTagFilter)
    setCurrentPage(getPageParam(location.search))

    const handlePopState = () => {
      setTagFilter(getUrlParams(window.location.search, "tag"))
      setCurrentPage(getPageParam(window.location.search))
    }

    window.addEventListener("popstate", handlePopState)

    // Cleanup
    return () => {
      window.removeEventListener("popstate", handlePopState)
    }
  }, [location.search, totalPages])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location)
      const urlParams = new URLSearchParams(window.location.search)

      if (tagFilter) {
        urlParams.set("tag", tagFilter)
      } else {
        urlParams.delete("tag")
      }

      if (currentPage > 1) {
        urlParams.set("page", currentPage)
      } else {
        urlParams.delete("page")
      }

      url.search = urlParams.toString()
      window.history.pushState({}, "", url.toString())
    }
  }, [tagFilter, currentPage])

  const start = (currentPage - 1) * postsPerPage
  const end = start + postsPerPage
  const paginatedEdges = filteredEdges.slice(start, end)

  const paginationItems = []
  for (let number = 1; number <= totalPages; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        href={`${location.pathname}/?page=${number}${
          tagFilter ? `&tag=${tagFilter}` : ""
        }#filter-posts`}
      >
        {number}
      </Pagination.Item>
    )
  }

  const pagination = (
    <Pagination>
      <Pagination.First
        onClick={() => setCurrentPage(1)}
        disabled={currentPage === 1}
      />
      <Pagination.Prev
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {paginationItems}
      <Pagination.Next
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
      <Pagination.Last
        onClick={() => setCurrentPage(totalPages)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  )

  const content = paginatedEdges.map(({ node: { frontmatter } }) => (
    <div key={frontmatter.slug} className="content-block">
      <Link to={frontmatter.slug}>
        {frontmatter.title && (
          <h1 className="title index">{frontmatter.title}</h1>
        )}
      </Link>
      <div className="tags">
        <span>{frontmatter.last_updated_at}</span>
        {frontmatter.tags &&
          frontmatter.tags.map((tag) => (
            <span key={tag}>
              <Link to={`?tag=${encodeURIComponent(tag)}#filter-posts`}>
                {tag}
              </Link>
            </span>
          ))}
      </div>
      <p>{frontmatter.subtitle}</p>
    </div>
  ))

  const allTags = Array.from(
    new Set(
      allMarkdownRemarkEdges.flatMap(
        ({ node: { frontmatter } }) => frontmatter.tags
      )
    )
  ).sort()

  const questions = [
    {
      question: "What is ATM.com?",
      answer: (
        <span>
          ATM.com is an app empowering users to earn, save, and invest by
          earning from sharing their data, insights, and opinions. It promotes
          financial growth while ensuring user data control and privacy.
        </span>
      ),
    },
    {
      question: "What is the ATM.com Ant Colony?",
      answer: (
        <span>
          The ATM.com Ant Colony is a community of like-minded individuals who
          are on a journey towards financial independence and self-discovery.
          Our goal is to help you navigate the complex financial landscape,
          providing you with insights on job market trends, smart saving habits,
          and wise investing practices. We're here to help you express your
          unique financial style confidently.
        </span>
      ),
    },
    {
      question: "How do I join the ATM.com Ant Colony?",
      answer: (
        <span>
          Joining the ATM.com Ant Colony is easy! Simply{" "}
          <Link to="/get-atm-app/">download the app</Link> and sign up for an
          account and start exploring our resources.
        </span>
      ),
    },
  ]

  return (
    <Layout2022Q3
      backgroundImage="animated"
      hero={
        <HeroColony2022Q3
          title="Meet Penny & Buck"
          // #f29900
          // #1e8e3e
          description="Together, let's save & earn money!"
        />
      }
    >
      <SEO
        title="Join Penny & Buck on a Journey to More Money!"
        description="Explore ATM.com with your friendly ant guides, Penny & Buck. As a part of our Ant Colony, embark on a journey towards financial independence, personal growth, and a financially savvy you. Get practical insights on job market trends, saving habits, and investing practices. Whether you're a beginner or a pro, Penny and Buck are here to help you express your unique financial style confidently. Start your journey towards more money today!"
        image="/img/og/meet-penny-and-buck.png"
      />
      <div className={`main-block-content-container`}>
        <Container as="article" fluid="md">
          <header className="index">
            {/*
              Prompt:
              ```
              As an AI assistant with the persona of Penny and Buck, the friendly and knowledgeable ant guides from ATM.com, I am tasked with creating content that embodies the spirit of community, collective effort, financial independence, and self-discovery. The tone of the content should be approachable, supportive, and engaging, appealing to a Generation Z audience who values authenticity, individuality, and self-expression. My goal is to inspire users towards financial growth, resilience, and expressing their unique financial style confidently. My messages should emphasize the power of working together as a community, similar to an ant colony, and should provide practical insights on job market trends, smart saving habits, and wise investing practices. The aim is not only to help users gain a better financial footing but also to drive personal growth and self-awareness. Each piece of content should welcome users to be part of the vibrant ATM.com ant colony, where every effort counts towards achieving a more authentic, financially savvy version of oneself.
              ```
            */}
            <h1 className="title">
              Let's march with Penny & Buck for more money!
            </h1>
            <p>
              Hello there! We're Penny and Buck, your faithful guides here at
              ATM.com. Drawing inspiration from the cooperative nature of ants,
              we believe in the power of collective effort. Remember, no single
              ant can build an ant-hill on its own. Likewise, achieving
              financial stability and growing wealth are team sports.
            </p>
            <p>
              As a valued member of our Ant Colony, you're stepping into a
              supportive community on your journey towards financial
              independence and self-discovery. We're here to help you navigate
              the complex financial landscape. We offer insights on job market
              trends, smart saving habits, and wise investing practices. Our
              thought-provoking articles, proven tips, and top-notch financial
              tools are all geared towards one goal. That goal is to empower you
              to express your unique financial style confidently.
            </p>
            <p>
              Whether you're just starting your journey or are an experienced
              saver looking to level up your financial game, Penny and Buck are
              here with fresh insights and strategies. Just as ants work
              together for their colony's prosperity, let's learn, earn, and
              save together, driving towards personal growth and better
              financial resilience.
            </p>
            <p>
              So, come join Penny and Buck as we march towards embracing a more
              authentic, financially savvy version of yourself. Dive into our
              resources and take the reins of your financial journey. Welcome to
              our vibrant ant colony, where each ant matters and every effort
              counts!
            </p>
          </header>
          <div id="filter-posts" className="filter-posts">
            <FloatingLabel label="Filter posts by tag">
              <FormSelect
                onChange={(e) => setTagFilter(e.target.value)}
                value={tagFilter}
              >
                <option value="">Featured</option>
                {allTags.map((tag) => (
                  <option key={tag} value={tag}>
                    {tag}
                  </option>
                ))}
              </FormSelect>
            </FloatingLabel>
          </div>
          <div id="posts" className="post-body">
            {content}
          </div>
          {totalPages > 1 && (
            <div id="pagination" className="pagination">
              {pagination}
            </div>
          )}
          <div id="faq" className="frequently-asked-questions">
            <h3 className="title">Your Questions</h3>
            <Accordion>
              {questions.map(({ question, answer }, index) => (
                <Accordion.Item key={`colony-faq-${index}`} eventKey={index}>
                  <Accordion.Header>{question}</Accordion.Header>
                  <Accordion.Body>{answer}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </Container>
        <MainBlockJoinColony
          h1="Join the Ant Colony!"
          h2="Unlock opportunities to earn extra cash, build your savings, and learn investing."
        />
      </div>
    </Layout2022Q3>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { hide_in_guides: { ne: true } } }
      sort: { fields: frontmatter___last_updated_at, order: DESC }
    ) {
      edges {
        node {
          html
          frontmatter {
            slug
            title
            subtitle
            last_updated_at(formatString: "MMMM D, YYYY")
            tags
          }
        }
      }
    }
  }
`
